import React, { Component } from 'react';
import { Consumer } from 'store/createContext';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import { navigate } from 'gatsby';
import Layout from 'components/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircleNotch,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Slider from '@material-ui/core/Slider';

import ResetPassword from 'components/login/ResetPassword';
import Login from 'components/login/login';

import {
  get_all_leads_for_user,
  backend_update_email_preference,
  backend_update_all_makes_select,
  backend_update_makes_selected,
  backend_update_up_to_cost,
  backend_update_age_range,
} from 'helpers/backend';

const ForgotPassword = styled.button`
  -webkit-appearance: none;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  box-shadow: none;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;

const EditBtn = styled.button`
  -webkit-appearance: none;
  width: 100%;
  background-color: #2cabde;
  border: none;
  border-radius: 8px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
  padding: 8px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  box-shadow: none;
  &:hover {
    opacity: 0.9;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  }
`;


const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 10px;
`;

const LoaderInner = styled.div`
  padding: 100px;
`;

const NumberPlate = styled.div`
  font-family: 'UKNumberPlate';
  -webkit-appearance: none;
  width: 100%;
  background-color: #ffeb00;
  border-style: none;
  border-color: #ffeb00;
  box-shadow: none;
  font-size: 30px;
  height: auto;
  text-align: center;
  padding: 2px 25px;
  border-radius: 8px;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  display: inline-block;
`;

const Wrapper = styled.div`
  border: 2px solid #e1e1e1;
  padding: 30px;
  height: 100%;
  position: relative;
`;

const StatusComplete = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  background: #28a745;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusInComplete = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  background: #dc3545;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transform: translateY(-2px);
  }
`;

const blueIcon = {
  color: '#2cabde',
};

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: '4px',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '2px solid #42413D',
    padding: '16px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#42413D',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const marks = [
  {
    value: 5000,
    label: '£5000',
  },
  {
    value: 25000,
    label: '£25,000',
  },
  {
    value: 50000,
    label: '£50,000',
  },
  {
    value: 75000,
    label: '£75,000',
  },
  {
    value: 100000,
    label: '£100,000+',
  },
];

const ageMarks = [
  {
    value: 2000,
    label: 2000,
  },
  {
    value: 2019,
    label: 2019,
  },
];

function valuetext(value) {
  return `${value}`;
}

function valueLabelFormat() {
  return '£';
}

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetPassword: false,
      loading: true,
      makeoptions: [],
      makes: [],
      leads: [],
      noLeads: '',
      first_name: '',
      last_name: '',
      dealerShipName: '',
      email: '',
      agreedToEmails: false,
      showMakes: false,
      upToCost: '',
      ageRange: [2000, 2019],
    };

    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.returnMakes = this.returnMakes.bind(this);
    this.editLead = this.editLead.bind(this);
    this.slideChangeValue = this.slideChangeValue.bind(this);
    this.ageSlideChangeValue = this.ageSlideChangeValue.bind(this);
  }

  componentDidMount() {
    let state = localStorage['appState'];
    if (!state) {
      navigate('/login');
    }

    if (state) {
      var basicAuthCredential = `${process.env.BACKEND_USERNAME}:${
        process.env.BACKEND_PASSWORD
      }`;
      var bace64 = btoa(basicAuthCredential);
      axios
        .get(
          `${process.env.BACKEND_URL}/user/${
            JSON.parse(localStorage['appState']).uid
          }?_format=hal_json`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/hal+json',
              'X-CSRF-Token': `${process.env.BACKEND_CSRF_TOKEN}`,
              Authorization: 'Basic ' + bace64,
            },
          }
        )
        .then(res => {
          if (res.data.field_purchases_all_brands) {
            this.setState({
              showMakes:
                res.data.field_purchases_all_brands[0].value == 'no'
                  ? true
                  : false,
              makeoptions:
                res.data.field_purchases_all_brands[0].value == 'no'
                  ? 'specific'
                  : 'all',
            });
          }
          if (
            res.data.field_purchases_from_year &&
            res.data.field_purchases_up_to_year
          ) {
            const updateAgeMarks = [
              res.data.field_purchases_from_year[0].value,
              res.data.field_purchases_up_to_year[0].value,
            ];
            this.setState({
              ageRange: updateAgeMarks,
            });
          }
          if (res.data.field_up_to_what_value_do_you_ty) {
            this.setState({
              upToCost: res.data.field_up_to_what_value_do_you_ty[0].value,
            });
          }
          if (res.data._embedded) {
            if (
              res.data._embedded[
                `${
                  process.env.BACKEND_URL_NON_HTTPS
                }/rest/relation/user/user/field_dealer_brands`
              ]
            ) {
              const brands =
                res.data._embedded[
                  `${
                    process.env.BACKEND_URL_NON_HTTPS
                  }/rest/relation/user/user/field_dealer_brands`
                ];

              var setSelectedMakes = brands.map(item => {
                const fullPath = item._links.self.href;
                const removeFront = fullPath.replace(
                  `${process.env.BACKEND_URL_NON_HTTPS}/taxonomy/term/`,
                  ''
                );
                const finalTid = removeFront.replace('?_format=hal_json', '');
                return Number(finalTid);
              });
              this.setState({
                makes: setSelectedMakes,
              });
            }
          }

          if (res.data.field_agreed_to_emails) {
            this.setState({
              agreedToEmails: res.data.field_agreed_to_emails[0].value,
            });
          }
          this.setState({
            first_name: res.data.field_first_name[0].value,
            last_name: res.data.field_last_name[0].value,
            dealerShipName: res.data.field_dealership_name[0].value,
            email: atob(JSON.parse(localStorage['appState']).email),
          });
          get_all_leads_for_user(JSON.parse(localStorage['appState']).uid).then(
            res => {
              if (res.data.length == 0) {
                this.setState({
                  noLeads: 'You have not listed any cars with us yet.',
                  loading: false,
                });
              } else {
                this.setState({
                  leads: res.data,
                  loading: false,
                  noLeads: '',
                });
              }
            }
          );
        });
    }
  }

  slideChangeValue(e, value) {
    this.setState({
      upToCost: value,
    });
    backend_update_up_to_cost(JSON.parse(localStorage['appState']).uid, value);
  }

  ageSlideChangeValue(e, value) {
    this.setState({
      ageRange: value,
    });
    backend_update_age_range(JSON.parse(localStorage['appState']).uid, value);
  }

  handleCheckChange(e) {
    this.setState({
      agreedToEmails: e.target.checked,
    });
    backend_update_email_preference(
      JSON.parse(localStorage['appState']).uid,
      e.target.checked
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name == 'makeoptions') {
      const option = e.target.value == 'specific' ? 'no' : 'yes';
      backend_update_all_makes_select(
        JSON.parse(localStorage['appState']).uid,
        option
      );
      this.setState({
        showMakes: e.target.value == 'specific' ? true : false,
      });
      if (e.target.value == 'all') {
        this.setState(
          {
            makes: [],
          },
          () => {
            backend_update_makes_selected(
              JSON.parse(localStorage['appState']).uid,
              this.state.makes
            );
          }
        );
      }
    }
    if (e.target.name == 'makes') {
      backend_update_makes_selected(
        JSON.parse(localStorage['appState']).uid,
        e.target.value
      );
    }
  }

  returnMakes(CarMakesDisplay, selected) {
    var retArray = new Array();
    {
      selected.map(id => (retArray[id] = CarMakesDisplay[id].name + ', '));
    }
    return retArray;
  }

  handleChange() {
    this.setState({
      resetPassword: !this.state.resetPassword,
    });
  }

  editLead(nid, reg, uuid) {
    axios
      .get(
        `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=${
          process.env.UKREG_LOOKUP
        }&user_tag=&key_VRM=` + reg
      )
      .then(res => {
        if (res.data.Response.StatusCode == 'Success') {
          const carData = res.data.Response;
          navigate('/listcar', {
            state: {
              nid: nid,
              uuid: uuid,
              carData: carData,
              edit: true,
            },
          });
        }
      });
  }

  render() {
    const {
      first_name,
      last_name,
      email,
      leads,
      noLeads,
      dealerShipName,
      loading,
      agreedToEmails,
      makeoptions,
      showMakes,
      makes,
      upToCost,
      ageRange,
    } = this.state;
    const handleCheckChange = this.handleCheckChange;
    const onChange = this.onChange;
    const returnMakes = this.returnMakes;
    const editLead = this.editLead;
    const CarMakesdata = this.props.data.allTaxonomyTermMake.edges;
    const slideChangeValue = this.slideChangeValue;
    const ageSlideChangeValue = this.ageSlideChangeValue;
    const CarMakesDisplay = new Array();
    {
      CarMakesdata.map(
        name =>
          (CarMakesDisplay[name.node.drupal_internal__tid] = {
            name: name.node.name,
          })
      );
    }
    return (
      <Consumer>
        {({ isLoggedIn, toggleLoginStatus }) => (
          <Layout>
            <div className="container mt-3 mt-lg-5">
              {loading && (
                <LoadingOverlay className="d-flex align-items-center justify-content-center">
                  <LoaderInner className="text-center">
                    <FontAwesomeIcon
                      className="fa-spin fa-5x"
                      style={blueIcon}
                      icon={faCircleNotch}
                    />
                  </LoaderInner>
                </LoadingOverlay>
              )}
              {isLoggedIn && (
                <div className="row">
                  <div className="col-12 col-lg-4 order-1 order-lg-0">
                    <h1 className="mb-4">My Details</h1>
                    <p className="mb-1 font-weight-bold">
                      {first_name} {last_name}
                    </p>
                    <p className="mb-1">{dealerShipName}</p>
                    <p>{email}</p>
                    <ResetPassword
                      open={this.state.resetPassword}
                      email={this.state.email}
                      handleChange={this.handleChange.bind(this)}
                    />
                    <ForgotPassword
                      onClick={e => this.setState({ resetPassword: true })}
                    >
                      Change account password
                    </ForgotPassword>
                    <h4 className="mt-4">Contact prefrences</h4>
                    <div className="my-2">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={agreedToEmails}
                            onChange={handleCheckChange}
                            value="agreedToEmails"
                            color="primary"
                          />
                        }
                        label="Email me when you have new cars"
                      />
                      {!agreedToEmails && (
                        <p className="text-danger">
                          You currently wont recieve emails about new cars!
                        </p>
                      )}
                    </div>
                    <h4 className="mt-4">Buying prefrences</h4>
                    <RadioGroup
                      aria-label="makeoptions"
                      name="makeoptions"
                      value={makeoptions}
                      onChange={onChange}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All brands"
                      />
                      <FormControlLabel
                        value="specific"
                        control={<Radio />}
                        label="Specific brands"
                      />
                    </RadioGroup>
                    {showMakes && (
                      <FormControl className="mb-4">
                        <p className="mb-1">Select Brands</p>
                        <Select
                          multiple
                          name="makes"
                          value={makes}
                          onChange={onChange}
                          input={<BootstrapInput id="outlined-car-brands" />}
                          renderValue={selected =>
                            returnMakes(CarMakesDisplay, selected)
                          }
                          MenuProps={MenuProps}
                        >
                          {CarMakesdata.map(name => (
                            <MenuItem
                              key={name.node.drupal_internal__tid}
                              value={name.node.drupal_internal__tid}
                            >
                              <Checkbox
                                checked={
                                  makes.indexOf(
                                    name.node.drupal_internal__tid
                                  ) > -1
                                }
                              />
                              <ListItemText primary={name.node.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <div>
                      <p className="mt-4 mb-4 font-weight-bold">
                        Up to what value do you typically purchase?
                      </p>
                      <Slider
                        value={Number(upToCost)}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        valueLabelDisplay="auto"
                        min={5000}
                        max={100000}
                        marks={marks}
                        onChange={slideChangeValue}
                      />
                    </div>
                    <div>
                      <p className="mt-4 mb-4 font-weight-bold">
                        What age range do you typically purchase?
                      </p>
                      <Slider
                        value={ageRange}
                        onChange={ageSlideChangeValue}
                        min={2000}
                        max={2019}
                        marks={ageMarks}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 offset-lg-1 order-0 order-lg-1">
                    <div className="row">
                      <div className="col-12 mb-4 mt-4 mt-lg-0">
                        <h3>My submitted cars</h3>
                      </div>
                      {noLeads && (
                        <div className="col-12 mb-4 mt-4 mt-lg-0">
                          <p className="font-weight-bold text-danger">
                            {noLeads}
                          </p>
                        </div>
                      )}
                      {leads.map((lead, index) => (
                        <React.Fragment key={index}>
                          <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                            <Wrapper className="d-flex align-content-around flex-wrap">
                              {lead.field_lead_completed == 'Yes' ? (
                                <StatusComplete>
                                  <FontAwesomeIcon
                                    className="text-white"
                                    style={{ color: '#2cabde' }}
                                    icon={faCheck}
                                  />
                                </StatusComplete>
                              ) : (
                                <StatusInComplete>
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="text-white"
                                  />
                                </StatusInComplete>
                              )}
                              <NumberPlate>
                                {lead.field_registration_plate}
                              </NumberPlate>
                              <div className="text-center py-5">
                                <img
                                  alt={lead.field_make}
                                  className="w-50"
                                  src={`https://cdn.vdicheck.com/badges/${
                                    lead.field_make
                                  }.png`}
                                />
                              </div>
                              <div className="text-center w-100">
                                <p className="font-weight-bold">
                                  {lead.field_first_registered.slice(0, 4)}
                                </p>
                                <p className="font-weight-bold">
                                  {lead.field_model}
                                </p>
                              </div>
                              <div className="w-100">
                                {lead.field_lead_completed == 'No' && (
                                  <EditBtn
                                    onClick={e =>
                                      editLead(
                                        lead.nid,
                                        lead.field_registration_plate,
                                        lead.uuid
                                      )
                                    }
                                  >
                                    Add images
                                  </EditBtn>
                                )}
                              </div>
                            </Wrapper>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {!isLoggedIn && <Login toggleLoginStatus={toggleLoginStatus} />}
            </div>
          </Layout>
        )}
      </Consumer>
    );
  }
}

export default Account;

Account.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTaxonomyTermMake {
      totalCount
      edges {
        node {
          relationships {
            node__lead {
              drupal_internal__nid
            }
          }
          drupal_internal__tid
          name
        }
      }
    }
  }
`;
